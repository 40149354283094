import React from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";

import './App.css';
import Header from "./Header/Header";
import Footer from './Footer/Footer';
import Home from "../../pages/Home/Home";
import Services from "../../pages/Services/Services";
import WhyAltonotch from '../../pages/Why_Altonotch/Why_Altonotch';
import Blog from "../../pages/Blog/Blog";
import BlogInner from "../../pages/Blog/InnerBlog/InnerBlog";
import DevOps from "../../pages/DevOps/DevOps";
import Contact from "../../pages/Contact/Contact";
import FourOFour from "../../pages/Error/FourOFour";
import ScrollToTop from "../ScrollToTop";
import Portfolio from "../../pages/Portfolio/Portfolio";

const supportHistory = 'pushState' in window.history;


class App extends React.Component {
    render() {
        const msg = `
		MMMMMMMMMMMMMMMMMMNkokNMMMMMMMMMMMMMMMMM
		MMMMMMMMMMMMMMMMMNk:;:kNMMMMMMMMMMMMMMMM
		MMMMMMMMMMMMMMMMNk:;;;cOWMMMMMMMMMMMMMMM
		MMMMMMMMMMMMMMMWkc;;;;;c0WMMMMMMMMMMMMMM
		MMMMMMMMMMMMMMWOc;;;;;;;l0WMMMMMMMMMMMMM
		MMMMMMMMMMMMMWOc;;;;;;;;;lKMMMMMMMMMMMMM
		MMMMMMMMMMMMWOc;;;;ldl;;;;oXWMMMMMMMMMMM
		MMMMMMMMMMMW0l;;;;oKWKo;;;;dXMMMMMMMMMMM
		MMMMMMMMMMW0l;;;;oKWMWKl;;;:dNMMMMMMMMMM
		MMMMMMMMMW0l;;;;lKWMMMW0l;;;:xNMMMMMMMMM
		MMMMMMMMWKl;;;;l0WMMMMMWOc;;;:kNMMMMMMMM
		MMMMMMMMKo;;;;cOWMMMMMMMWOc;;;cOWMMMMMMM
		MMMMMMMKo;;;;;lkXWMMMMMMMNk:;;;cOWMMMMMM
		MMMMMMXo;;;;;;;;cdOXNWMMMMNx:;;;l0WMMMMM
		MMMMMXd:;;;;:ccccccodx0NMMMXd:;;;lKWMMMM
		MMMMXd:;;;:xKXXXXXXXXXXWMMMMXo;;;;oKMMMM
		MMMXd:;;;:xNMMMMMMMMMMMMMMMMWKo;;;;dXMMM
		MMNx:;;;;dXMMMMMMMMMMMMMMMMMMW0l;;;:dXMM
		MNx:;;;;oXMMMMMMMMMMMMMMMMMMMMWOc;;;:xNM
		W0l::::l0WMMMMMMMMMMMMMMMMMMMMMNxc:::l0M

`;
        console.log(msg);
        return (
            <div className="App">
                <BrowserRouter forceRefresh={!supportHistory}>
                    <div className="mainPageContainer">
                        <Header/>
                        <div className="page-content">
                            <Switch>
                                <Route exact path="/" component={Home} />
                                <Route path="/services" component={Services}/>
                                <Route path="/why-altonotch" component={WhyAltonotch}/>
                                <Route exact path="/blog/:slug" component={BlogInner}/>
                                <Route path="/insights" component={Blog}/>
                                <Route path="/Portfolio" component={Portfolio}/>
                                <Route path="/devops" component={DevOps}/>
                                <Route path="/contact" component={Contact}/>
                                <Route component={FourOFour} />
                            </Switch>
                        </div>
                        <Footer/>
                        <ScrollToTop/>
                    </div>
                </BrowserRouter>
            </div>
        );
    }
}

export default App;
