import React from 'react';
import DocumentTitle from 'react-document-title'

import './FourOFour.css';
import ContactForm from "../../components/ContactForm/ContactForm";
import contactImg from './404.png';

class FourOFour extends React.Component {
    render() {
        return (
            <div className="fourofour-page-content">
				<DocumentTitle title="Altonotch | Oops... You broke the internet!" />
                <div className="landing-strip row-block">
                    <section className="container">
                        <h1>Oops... You broke the internet!</h1>
                        <p>Looks like you can really do with our services. We are a top notch team that can help you make heads and tails of the web.
                        </p>
                    </section>
                </div>
				<div className="row-block">
                    <div className="container">
                        <div className="row">
                            <section className="col-md-8">
								<h2>Contact for Help</h2>
								<ContactForm origin="FourOFourForm" />
                            </section>
							<section className="col-md-4">
								<img className="img-fluid" alt="contact" src={contactImg}/>
							</section>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FourOFour;
