import React from "react";
import { Link } from "react-router-dom";
import { BrowserView, isBrowser } from "react-device-detect";
import DocumentTitle from 'react-document-title';

import './Home.css';
import cloudImage from './cloud-image.png';
import logsVideo from './logs.mp4';
import awsLogo from './aws.png';
import terraformLogo from './terraform.png';
import dockerLogo from './docker.png';
import pythonLogo from './python.png';
import jenkinsLogo from './jenkins.png';
import reactLogo from './reactjs.png';
import homeAboutPic from './home-about.png';


class Home extends React.Component {
    render() {
        return (
            <div className="home-page-content">
				<DocumentTitle title="Altonotch - Take advantage of the web" />
                {/*<BrowserView device={isBrowser}>
                    <video preload="auto" autoPlay muted loop>
                        <source src={logsVideo} type="video/mp4"/>
                    </video>
                </BrowserView>*/}
                <div className="landing-info">
                    <div className="container">
                        <div className="row">
                            <section className="col-md-6">
                                <h1>
                                    Take advantage of the web
                                </h1>
                                <p>Optimizing your tech stack. Expediting development.<br/>
                                    We create a deeply engaging web experience.</p>
                                <Link className="contact-us btn btn-primary" to="/contact">Let's build together</Link>
                            </section>
                            {/*<section className="col-md-6">
                                <img className="img-fluid" alt="cloud" src={cloudImage}/>
                            </section>*/}
                        </div>
                    </div>
                </div>
                <div className="row-block tech-stack">
                    <div className="container">
                        <div className="row">
                            <section className="col-md-2 col-4">
                                <img className="img-fluid" alt="aws logo" src={awsLogo}/>
                            </section>
                            <section className="col-md-2 col-4">
                                <img className="img-fluid" alt="docker logo" src={dockerLogo}/>
                            </section>
                            <section className="col-md-2 col-4">
                                <img className="img-fluid" alt="python logo" src={pythonLogo}/>
                            </section>
                            <section className="col-md-2 col-4">
                                <img className="img-fluid" alt="jenkins logo" src={jenkinsLogo}/>
                            </section>
                            <section className="col-md-2 col-4">
                                <img className="img-fluid" alt="react logo" src={reactLogo}/>
                            </section>
                            <section className="col-md-2 col-4">
                                <img className="img-fluid" alt="terraform logo" src={terraformLogo}/>
                            </section>
                        </div>
                    </div>
                </div>
                <div className="row-block home-about">
                    <div className="container">
                        <div className="row">
                            <section className="col-md-6">
                                <h2>About Us</h2>
                                <p>
                                    At Altonotch we help companies research, design and engineer their
                                    products. Our approach is methodical, innovative and extensible.
                                    Close collaboration with your team assures seamless integration into
                                    existing infrastructure.
                                </p>
                                <p>
                                    Our analytical approach allows us to solve problems efficiently
                                    with stability and maintainability, ensuring your project is on time and within budget.
                                </p>
								<p>
									Based in Israel, we are a team of top notch developers who reach out globally to spread
									our love and passion for technology and provide expert consulting in the field.
								</p>
                            </section>
                            <section className="col-md-6">
                                <img className="img-fluid" alt="about us" src={homeAboutPic}/>
                            </section>
                        </div>
                    </div>
                </div>
                {/*
                <div className="row-block home-blog">
                    <div className="container">
                        <div className="row">
                            <section className="col-md-4">
                                <h2>Latest Insight</h2>
                            </section>
                            <section className="col-md-8">
                                <div className="latest-blog">
                                    <h3>Blog Title</h3>
                                    <p>Morbi ullamcorper bibendum tortor, eu pulvinar mauris semper nec.
                                        Proin erat augue, porttitor at sapien sit amet, molestie congue justo.
                                        Cras interdum ultrices justo, et iaculis tellus.
                                        Ut eu sapien porta, aliquet tellus eu, placerat enim.
                                        Donec dolor tortor, sagittis non massa eu, scelerisque luctus orci.
                                    </p>
                                    <Link className="btn btn-primary" to="#">Read more >></Link>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
                */}
            </div>
        );
    }
}

export default Home;
