import React from "react";
import { Link } from "react-router-dom";

import './Footer.css';


class Footer extends React.Component {
    render() {
        return (
            <footer className="row-block">
                <div className="container">
                    <div className="row">
                        <section className="col-md-6">
                            <h4>Let’s build something great together</h4>
                            <Link to="/contact" className="btn btn-primary">GET IN TOUCH</Link>
                        </section>

                        <section className="col-md-6">
                            <div className="social">
                                <a className="facebook" href="https://www.facebook.com/altonotch/" target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-facebook-f" />
                                </a>
                                <a className="linkedin" href="https://www.linkedin.com/company/altonotch/" target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-linkedin-in" />
                                </a>
								<a className="twitter" href="https://twitter.com/altonotch/" target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-twitter" />
                                </a>
                            </div>
                            <p className="copyright">© Copyright <Link to="/">altonotch.com</Link> | All Rights Reserved </p>
                        </section>
                    </div>
                </div>
            </footer>

        );
    }
}

export default Footer;
