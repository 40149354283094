import React from "react";
import { connect } from "react-redux";
import DocumentTitle from "react-document-title";

import "./Portfolio.css";
import PortfolioProject from "../../components/PortfolioProject";

const mapStateToProps = store => {
  return {
    projects: []
  };
};

class Portfolio extends React.Component {
  constructor() {
    super();
    this.state = {
      winHeight: 0,
      projects: [
        {
          name: "Questabiz",
          image: "https://blogs.altonotch.com/content/images/2018/01/first-blog.png",
          summary: "test",
          link: "http://there.com"
        }
      ]
    };
  }

  componentDidMount() {
    let winHeight =
      window.innerHeight - (this.refs.landingStrip.clientHeight + 70);
    this.setState({ winHeight });
  }

  render() {
    const { projects } = this.state;
    let listHolder = [];
    if (projects.length > 0) {
      listHolder = projects.map(project => {
        return <PortfolioProject project={project} key={project.name} />;
      });
    }
    return (
      <div className="portfolio-page-content">
        <DocumentTitle title="Altonotch | Portfolio" />
        <div className="landing-strip row-block" ref="landingStrip">
          <section className="container">
            <h1>Our latest projects</h1>
            <p>
              We are constantly striving to innovate and research new trends.
              Catch up on our latest projects, technological advancements and
              case studies.
            </p>
          </section>
        </div>
        <div className="portfolio-archives row-block">
          <div
            style={{ minHeight: this.state.winHeight + "px" }}
            className="container"
          >
            {listHolder}
          </div>
        </div>
      </div>
    );
  }
}

// Connect our Portfolio component to Redux store management
const VisiblePortfolioProjects = connect(mapStateToProps)(Portfolio);
export default VisiblePortfolioProjects;
