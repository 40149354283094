import React from "react";
import DocumentTitle from 'react-document-title'

import './Why_Altonotch.css';
import betterTogetherImg from './better-together.png';


class WhyAltonotch extends React.Component {
    render() {
        return (
            <div className="why-altonotch-page-content">
				<DocumentTitle title="Altonotch | Why Altonotch" />
                <div className="landing-strip row-block">
                    <section className="container">
                        <h1>Rely on our expertise</h1>
                        <p>
                            Our team of top notch experts has years of experience in the field and knows what it takes
                            to get the job done the right way, ensuring your long-term success.
                        </p>
                    </section>
                </div>
                <div className="why-us row-block">
                    <div className="container">
                        <div className="row">
                            <section className="col-md-3">
                                <i className="fa fa-lightbulb" />
                                <h3>Innovation</h3>
                                <p>
                                    We provide access to the best resources at all times because we pioneer the field.
                                </p>
                            </section>
                            <section className="col-md-3">
                                <i className="fa fa-arrows-alt" />
                                <h3>Diversity</h3>
                                <p>
                                    We apply a variety of technologies, tailored to your particular requirements.
                                </p>
                            </section>
                            <section className="col-md-3">
                                <i className="fa fa-rocket" />
                                <h3>Quality</h3>
                                <p>
                                    Make an impact and stand out from the crowd with our top notch solutions.
                                </p>
                            </section>
                            <section className="col-md-3">
                                <i className="fa fa-comment-alt" />
                                <h3>Support</h3>
                                <p>
                                    We set up and ensure continued success with close and ongoing collaboration.
                                </p>
                            </section>
                        </div>
                    </div>
                </div>
                <div className="row-block better-together">
                    <div className="container">
                        <div className="row">
                            <section className="col-md-4">
                                <img className="img-fluid" alt="better-together"
                                     src={betterTogetherImg}/>
                            </section>
                            <section className="col-md-8">
                                <h2>Better Together</h2>
                                <p>
                                    Our collaborative approach delivers better applications,
                                    ensuring seamless integration into existing infrastructures, while exposing your
                                    team to best practices and new processes. Proper architecture and implementation
                                    guarantees reduced costs and optimized performance.
                                </p>
                                <p>
                                    This is how we help companies upgrade to new technologies and sharpen your skills.
                                </p>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default WhyAltonotch;
