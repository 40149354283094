import React from "react";
import DocumentTitle from 'react-document-title'
import { Link } from 'react-router-dom'

import './DevOps.css';
import awsLogo from './aws.png';
import dockerLogo from './docker.png';
import kubernetesLogo from './kubernetes.png';
import jenkinsLogo from './jenkins.png';
import terraformLogo from './terraform.png';
import travisLogo from './travis.png';
import hereForYouImg from './here-for-you.png';

class DevOps extends React.Component {
    render() {
        return (
            <div className="devops-page-content">
                <DocumentTitle title="Altonotch | DevOps" />
                <div className="landing-strip row-block">
                    <section className="container">
                        <h1>DevOps consulting and services</h1>
                        <p>
                            From assessing your current DevOps practices, providing consulting regarding the optimum direction,
                            configuring the automation, to recommending the best tools for ongoing maintenance<br/>
                            <strong>- our team at Altonotch have it all taken care of.</strong>
                        </p>
                    </section>
                </div>
                <div className="row-block tech-stack">
                    <div className="container">
                        <div className="row">
                            <section className="col-md-2 col-4">
                                <img className="img-fluid" alt="aws logo" src={awsLogo}/>
                            </section>
                            <section className="col-md-2 col-4">
                                <img className="img-fluid" alt="docker logo" src={dockerLogo}/>
                            </section>
                            <section className="col-md-2 col-4">
                                <img className="img-fluid" alt="jenkins logo" src={jenkinsLogo}/>
                            </section>
                            <section className="col-md-2 col-4">
                                <img className="img-fluid" alt="terraform logo" src={terraformLogo}/>
                            </section>
                            <section className="col-md-2 col-4">
                                <img className="img-fluid" alt="kubernetes logo" src={kubernetesLogo}/>
                            </section>
                            <section className="col-md-2 col-4">
                                <img className="img-fluid" alt="travis logo" src={travisLogo}/>
                            </section>
                        </div>
                    </div>
                </div>
                <div className="row-block here-for-you">
                    <div className="container">
                        <div className="row">
                            <section className="col-md-8">
                                <h2>We are here for you</h2>
                                <p>
                                    Based in Israel, we are a boutique company of top notch developers
                                    who strive to spread our love and passion for technology and specialize in building
                                    long lasting relationships with our clients, including hands-on support and on-premise consulting.
                                </p>
                                <p>
                                    With our DevOps consulting, we help startups and large enterprises optimize their
                                    Development and Operations to achieve higher efficiency, faster time to market and
                                    better quality of software builds with early identification of potential issues,
                                    allowing the code to be constantly in a releasable state.
                                </p>
                                <p>
                                    With our DevOps services, we leverage collaboration, monitoring,
                                    tool-chain pipelines, automation and Cloud adoption.
                                    We ensure rapid launching of applications by automating end-to-end delivery pipelines and
                                    facilitate smooth integration and development across leading cloud platforms.
                                </p>
                                <h4>Contact us for a FREE 3 hour consultation</h4>
                                <Link to="/contact" className="btn btn-primary">Get Started</Link>
                            </section>
                            <section className="col-md-4">
                                <img className="img-fluid" alt="here-for-you"
                                     src={hereForYouImg}/>
                            </section>
                        </div>
                    </div>
                </div>
                <div className="devops-services row-block">
                    <div className="container">
                        <div className="row">
                            <section className="col-md-4 col-sm-6">
                                <div className="tile">
                                    <i className="fas fa-clipboard" aria-hidden="true" />
                                    <h3>Consulting</h3>
                                    <p>
                                        Our experts provide sound advice based on our vast experience and best practices
                                        to ensure longterm reliability.
                                    </p>
                                </div>
                            </section>
                            <section className="col-md-4 col-sm-6">
                                <div className="tile">
                                    <i className="fas fa-search" aria-hidden="true" />
                                    <h3>Assessment</h3>
                                    <p>
                                        With our thorough approach we delve into the depths of your existing infrastructure
                                        to identify the right direction for you to take.
                                    </p>
                                </div>
                            </section>
                            <section id="frontend" className="col-md-4 col-sm-6">
                                <div className="tile">
                                    <i className="fa fa-cogs" aria-hidden="true" />
                                    <h3>Automation</h3>
                                    <p>
                                        While setting up and automating the pipeline,
                                        we prevent deployment issues and increase productivity by recommending robust tools.
                                    </p>
                                </div>
                            </section>
                            <section className="col-md-4 col-sm-6">
                                <div className="tile">
                                    <i className="fas fa-server" aria-hidden="true" />
                                    <h3>Management</h3>
                                    <p>
                                        We take care of release management, continuous deployment,
                                        replica environment and new server setup.
                                    </p>
                                </div>
                            </section>
                            <section className="col-md-4 col-sm-6">
                                <div className="tile">
                                    <i className="fas fa-tachometer-alt" aria-hidden="true" />
                                    <h3>Performance</h3>
                                    <p>
                                        Great emphasis is placed on performance optimization on an ongoing basis
                                        for a reliable and stable infrastructure.
                                    </p>
                                </div>
                            </section>
                            <section className="col-md-4 col-sm-6">
                                <div className="tile">
                                    <i className="fas fa-shield-alt" aria-hidden="true" />
                                    <h3>Security</h3>
                                    <p>
                                        Vulenrability tests are performed throughout the process
                                        and alerts are set up for a constantly secure environment.
                                    </p>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DevOps;
