import {FETCH_BLOGS, FETCH_BLOGS_FULFILLED, FETCH_BLOGS_REJECTED} from "../types";


const initialState = {blog: [], fetching: false, error: null};

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case FETCH_BLOGS: {
            return {...state, fetching: true}
        }
        case FETCH_BLOGS_REJECTED: {
            return {...state, fetching: false, error: action.error}
        }
        case FETCH_BLOGS_FULFILLED: {
            return {
                ...state,
                fetching: false,
                blog: action.data,
            }
        }
        default:
            return state;
    }
}
