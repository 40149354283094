import React from "react";
import * as axios from "axios";

import './ContactForm.css';

class ContactForm extends React.Component {
	constructor() {
		super();
		this.state = {contact_data: {name: "", email: "", phone: "", company: "", message: ""}, error: false, sending: false, success: null };

		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(e) {
		let newState = {...this.state};
		newState.contact_data[e.target.name] = e.target.value;
		this.setState(newState);
	}

	handleSubmit(e) {
		e.preventDefault();
		this.setState({sending: true, success: null, error: false});
		axios.post("/api/contact/us/", {...this.state.contact_data, source: this.props.origin})
			.then(response => {
				this.setState({contact_data: {name: "", email: "", phone: "", company: "", message: ""}, sending: false, success: true});
			}).catch(error => {
				console.log('error sending email', error);
				this.setState({sending: false, error: true});
			});
	}

	render() {
        return (
            <form id="contactForm" onSubmit={this.handleSubmit}>
				<input type="text" value={this.state.contact_data.name} name="name" onChange={this.handleChange} placeholder="Name" required="required"/>
				<input type="email" value={this.state.contact_data.email} name="email" onChange={this.handleChange} placeholder="Email" required="required"/>
				<input type="tel" value={this.state.contact_data.phone} name="phone" onChange={this.handleChange} placeholder="Phone" required="required" pattern="\d*"/>
				<input type="text" value={this.state.contact_data.company} name="company" onChange={this.handleChange} placeholder="Company"/>
				<textarea rows="4" value={this.state.contact_data.message} name="message" onChange={this.handleChange} placeholder="Message" required="required"/>
				<input type="submit" id="submit" className="btn btn-primary" value="Submit"/>
				{this.state.sending ? <div><i className="fas fa-circle-notch fa-spin" /></div> : null }
				{this.state.success ? <p className="success">Thank you for your message. We will get back to you as soon as possible.</p> : null }
				{this.state.error ? <p className="error">An error occurred, please try again.</p> : null }
			</form>
        );
    }
}

export default ContactForm;
