import React from "react";
import DocumentTitle from 'react-document-title'

import './Services.css';

class Services extends React.Component {
    render() {
        return (
            <div className="services-page-content">
				<DocumentTitle title="Altonotch | Services" />
                <div className="landing-strip row-block">
                    <section className="container">
                        <h1>How we can help you</h1>
                        <p>
                            Our team of experts in software design and development provides
                            full stack service, staff augmentation, training, code and infrastructure audits,
                            to help take your project to the next level.
                        </p>
                    </section>
                </div>
                <div className="our-services row-block">
                    <div className="container">
                        <div className="row">
                            {/*<section id="mobileDevelopment" className="col-md-4 col-sm-6">
                                <div className="tile">
                                    <i className="fa fa-mobile-alt" aria-hidden="true" />
                                    <h3>Mobile App Development</h3>
                                    <p>
                                        Cross platform native mobile applications, ensure you reach users both on iOS
                                        and Android,
                                        while making it easy to iterate, experiment and maintain.
                                    </p>
                                </div>
                            </section>*/}
                            <section id="devOps" className="col-md-4 col-sm-6">
                                <div className="tile">
                                    <i className="fa fa-cogs" aria-hidden="true" />
                                    <h3>DevOps</h3>
                                    <p>
                                        After taking a look at your current DevOps practices, we provide consulting regarding the most advised direction
                                        and recommend the tools for ongoing maintenance.
                                    </p>
                                </div>
                            </section>
                            {/*<section id="webDevelopment" className="col-md-4 col-sm-6">
                                <div className="tile">
                                    <i className="fa fa-desktop" aria-hidden="true" />
                                    <h3>Web App Development</h3>
                                    <p>
                                        Our team uses the best technologies to ensure progressive web app performance,
                                        through high quality code and architecture,
                                        that is cost effective and allows for your business to grow
                                    </p>
                                </div>
                            </section>*/}
                            <section id="backEnd" className="col-md-4 col-sm-6">
                                <div className="tile">
                                    <i className="fas fa-server" aria-hidden="true" />
                                    <h3>Backend Development</h3>
                                    <p>
                                        We pride ourselves on using best practices for scalable and monitored backend systems,
                                        utilizing high quality code and architecture
                                        that is cost effective and allows for your business to grow.
                                    </p>
                                </div>
                            </section>
                            <section id="frontend" className="col-md-4 col-sm-6">
                                <div className="tile">
                                    <i className="fas fa-laptop-code" aria-hidden="true" />
                                    <h3>Frontend Development</h3>
                                    <p>
                                        With expertise in creating beautiful UIs with emphasis on good user experience, we take advantage
                                        of the leading front end technologies such as ReactJS.
                                    </p>
                                </div>
                            </section>
                            <section id="technology" className="col-md-4 col-sm-6">
                                <div className="tile">
                                    <i className="fas fa-project-diagram" aria-hidden="true" />
                                    <h3>Technology</h3>
                                    <p>
                                        We use technologies proven and supported by large open-source communities,
                                        adopted by leading companies such as Google, Facebook, Apple and Microsoft,
                                        for building scalable and maintainable applications.
                                    </p>
                                </div>
                            </section>
                            <section id="training" className="col-md-4 col-sm-6">
                                <div className="tile">
                                    <i className="fa fa-users" aria-hidden="true" />
                                    <h3>Training</h3>
                                    <p>
                                        We provide your team with thorough training on the technologies and infrastructure integrated into your system
                                        and make sure that you can continue to update and maintain with ease.
                                    </p>
                                </div>
                            </section>
                            <section id="support" className="col-md-4 col-sm-6">
                                <div className="tile">
                                    <i className="fas fa-comment-dots" aria-hidden="true" />
                                    <h3>Support and Maintenance</h3>
                                    <p>
                                        After the launch, we continue collaborating with you and your team,
                                        through training sessions, to ensure
                                        comfort with the solution and success moving forward.
                                    </p>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Services;
