import React from "react";
import { Link } from "react-router-dom";

class PortfolioProject extends React.Component {
  render() {
    const { project } = this.props,
      projectPostURI = project.link;

    return (
      <div className="row">
        <section className="col-md-12">
          <div className="clearfix portfolio-row">
            <section className="col-md-12">
              <Link className="portfolio-img" to={projectPostURI}>
                <img
                  className="img-fluid"
                  alt="portfolio"
                  src={project.image}
                />
              </Link>
              <Link to={projectPostURI}>
                <h2>{project.title}</h2>
              </Link>
              <p>{project.summary}</p>
              <Link className="btn btn-primary" to={projectPostURI}>
                Go To Project >>
              </Link>
            </section>
          </div>
        </section>
      </div>
    );
  }
}

export default PortfolioProject;
