import {FETCH_BLOG, FETCH_BLOG_FULFILLED, FETCH_BLOG_REJECTED} from "../types";


const initialState = {article: [], fetching: true, error: null};

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case FETCH_BLOG: {
            return {...state, fetching: true}
        }
        case FETCH_BLOG_REJECTED: {
            return {...state, fetching: false, error: action.error}
        }
        case FETCH_BLOG_FULFILLED: {
            return {
                ...state,
                fetching: false,
                article: action.data,
            }
        }
        default:
            return state;
    }
}
