import {FETCH_BLOGS_FULFILLED, FETCH_BLOGS, FETCH_BLOGS_REJECTED, FETCH_BLOG, FETCH_BLOG_FULFILLED, FETCH_BLOG_REJECTED} from "../types";
import * as axios from "axios";

export function fetchBlogs() {
    return function(dispatch) {
        dispatch({type: FETCH_BLOGS});
        axios.get("https://blogs.altonotch.com/api/blog/articles")
            .then((response) => {
                dispatch({type: FETCH_BLOGS_FULFILLED, data: response.data.articles})
            })
            .catch((err) => {
                dispatch({type: FETCH_BLOGS_REJECTED, error: err})
            })
    }
}

export function fetchBlog(slug) {
    return function(dispatch) {
        dispatch({type: FETCH_BLOG});
        axios.get(`https://blogs.altonotch.com/api/blog/articles/${slug}`)
            .then((response) => {
                dispatch({type: FETCH_BLOG_FULFILLED, data: response.data.article})
            })
            .catch((err) => {
                dispatch({type: FETCH_BLOG_REJECTED, error: err})
            })
    }
}