import React from "react";
import { connect } from "react-redux";
import DocumentTitle from 'react-document-title'

import './Blog.css';
import * as blogActions from "../../actions/blogActions";
import BlogArticle from "../../components/BlogArticle";


const mapStateToProps = store => {
    return {
        articles: store.blog.blog,
        isLoading: store.blog.fetching,
        hasError: store.blog.error !== undefined,
        errorMessage: store.blog.error,
    }
};


class Blog extends React.Component {
	constructor() {
		super();
		this.state = {winHeight: 0};
	}

	componentWillMount() {
		if (this.props.articles.length === 0) {
			this.props.dispatch(blogActions.fetchBlogs());
		}
    }

	componentDidMount() {
		let winHeight = window.innerHeight - (this.refs.landingStrip.clientHeight + 70);
		this.setState({ winHeight });
    }

	render() {
	    let listHolder = [];
	    if (this.props.articles.length > 0) {
		    listHolder = this.props.articles.map((post) => {return <BlogArticle post={post} key={post.slug}/>});
        }
		return (
			<div className="blog-page-content">
				<DocumentTitle title="Altonotch | Insights" />
				<div className="landing-strip row-block" ref="landingStrip">
					<section className="container">
						<h1>Our latest insights</h1>
						<p>
							We are constantly striving to innovate and research new trends. Catch up on our latest news, technological advancements and case studies.
						</p>
					</section>
				</div>
				<div className="blog-archives row-block">
					<div style={{minHeight: this.state.winHeight + 'px'}} className="container">
						{listHolder}
						{this.props.isLoading ? <div className="blog-loading"><i className="fas fa-circle-notch fa-spin"/></div> : null }
					</div>
				</div>
			</div>
		);
	}
}


// Connect our Blog component to Redux store management
const VisibleBlogPosts = connect(mapStateToProps)(Blog);
export default VisibleBlogPosts;
